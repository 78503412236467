import React from "react"
import Row from "react-bootstrap/Row"
import Container from "react-bootstrap/Container"
import Col from "react-bootstrap/Col"
import Carousel from "react-bootstrap/Carousel"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const WorkWrapper = styled.section`
  background-color: #141414;
  .carousel-items {
    margin-top: 50px;
    .gatsby-image-wrapper{
      margin: 0 auto;
    }
  }
  .carousel-indicators {
    bottom: -80px;
    display: flex;
    align-items: center;
    li {
      padding: 0;
      height: 8px;
      width: 8px;
      border-radius: 100%;
      background-color: #9c9c9c;
      &.active{
        height: 12px;
        width: 12px;
      }
    }
  }
`

const WorksSection = () => {
  const data = useStaticQuery(graphql`
    query {
      image1: file(relativePath: { eq: "chapel-street.png" }) {
        ...worksImage
      }

      image2: file(relativePath: { eq: "dars.png" }) {
        ...worksImage
      }

      image3: file(relativePath: { eq: "vau.png" }) {
        ...worksImage
      }
    }
  `)

  return (
    <WorkWrapper className="section">
      <Container>
        <div className="section-info text-center">
          <div className="si-title">
            <h2>My Portfolio</h2>
            <span>Recent Works</span>
          </div>
          <Row className="justify-content-center">
            <Col xs={8}>
              <div className="si-desc">
                <p>
                  Below are the series of my works which I was a part of. All
                  the projects listed below are accomplished while I was working
                  with different <span>Organizations</span> or doing freelancing
                  in different times. I am nothing but proud to be part of those
                  organizations and these projects.
                </p>
              </div>
            </Col>
          </Row>
        </div>

        <Row className="justify-content-center">
          <Col xs={8}>
            <div className="carousel-items">
              <Carousel controls={false}>
                <Carousel.Item>
                  <Img
                    fluid={data.image1.childImageSharp.fluid}
                    alt="100% End Result"
                  />
                </Carousel.Item>
                <Carousel.Item>
                  <Img
                    fluid={data.image2.childImageSharp.fluid}
                    alt="100% End Result"
                  />
                </Carousel.Item>
                <Carousel.Item>
                  <Img
                    fluid={data.image3.childImageSharp.fluid}
                    alt="100% End Result"
                  />
                </Carousel.Item>
              </Carousel>
            </div>
          </Col>
        </Row>
      </Container>
    </WorkWrapper>
  )
}

export default WorksSection

export const query = graphql`
  fragment worksImage on File {
    childImageSharp {
      fluid(maxHeight: 350) {
        ...GatsbyImageSharpFluid
        ...GatsbyImageSharpFluidLimitPresentationSize
      }
    }
  }
`
