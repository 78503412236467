import React from 'react'
import Banner from '../components/Banner'
import Features from '../components/Features'
import WorksSection from "../components/WorksSection"
import Testimonials from "../components/Testimonials"
import Form from "../components/Form"
import Footer from "../components/Footer"
import Layout from "../components/Layout"

const BannerContent = {
  title: "Ankit Sigdel || Web and Business Developer",
  description:
    "Hello, I am <span>Ankit Sigdel</span>, from the beautiful country of 'Himalayas', Nepal. I am a full time Front End Freelancer, with extensive knowledge of Graphics Designing and Backend Development.",
}


const Home = () => {
  return (
    <Layout>
      <Banner
        title={BannerContent.title}
        description={BannerContent.description}
      ></Banner>
      <Features></Features>
      <WorksSection></WorksSection>
      <Testimonials></Testimonials>
      <Form></Form>
      <Footer></Footer>
    </Layout>
  )  
}


export default Home