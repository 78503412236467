import React from 'react'
import Row from 'react-bootstrap/Row'
import Container from "react-bootstrap/Container"
import Col from "react-bootstrap/Col"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const FeatureWrapper = styled.section`
  background-color: #1c1c1c;
  position: relative;
  padding-bottom: 0;
  z-index: 1;
  &:after {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #141414;
    height: 30%;
    content: "";
    z-index: -1;
  }
  .each-feature {
    padding: 40px;
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.21);
    text-align: center;
    margin-top: 50px;
    background-color: #1c1c1c;
    .ef-icon {
      margin-bottom: 40px;
      .gatsby-image-wrapper {
        margin: 0 auto;
      }
    }
    .ef-title {
      text-transform: uppercase;
      margin-bottom: 20px;
      h3 {
        margin-bottom: 0;
        font-size: 20px;
        line-height: 30px;
      }
    }
    .ef-desc {
      p {
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
`

const Features = () => {

  const data = useStaticQuery(graphql`
    query {
      image1: file(relativePath: { eq: "skill-problemsolver.png" }) {
        ...featuresImage
      }

      image2: file(relativePath: { eq: "skill-frontend.png" }) {
        ...featuresImage
      }

      image3: file(relativePath: { eq: "skill-backend.png" }) {
        ...featuresImage
      }
    }
  `)

 return (
   <FeatureWrapper className="section">
     <Container>
       <div className="section-info text-center">
         <div className="si-title">
           <h2>My Expertise</h2>
           <span>What I Do</span>
         </div>
         <Row className="justify-content-center">
           <Col xs={8}>
             <div className="si-desc">
               <p>
                 I provide services at various level, such as, Web Development,
                 Business Consulting and Connect Client to a group of potential
                 freelancer in different field of expertise.
               </p>
             </div>
           </Col>
         </Row>
       </div>
       <Row>
         <Col>
           <div className="each-feature">
             <div className="ef-icon">
               <Img
                 fluid={data.image1.childImageSharp.fluid}
                 alt="Frontend Development"
               />
             </div>
             <div className="ef-title">
               <h3>
                 Application <br /> Architecture
               </h3>
             </div>
             <div className="ef-desc">
               <p>
                 I am able to cater every possible ideas in terms of drawing a
                 quality picture of an organization's business and there unique
                 exposure to the competitive market.
               </p>
             </div>
           </div>
         </Col>
         <Col>
           <div className="each-feature">
             <div className="ef-icon">
               <Img
                 fluid={data.image2.childImageSharp.fluid}
                 alt="Frontend Development"
               />
             </div>
             <div className="ef-title">
               <h3>
                 FrontEnd <br /> Programming
               </h3>
             </div>
             <div className="ef-desc">
               <p>
                 I possess wild compassion of developing Pixel Perfect front end
                 development. I write efficient and precise code, meet all the
                 standard of front end programming.
               </p>
             </div>
           </div>
         </Col>
         <Col>
           <div className="each-feature">
             <div className="ef-icon">
               <Img
                 fluid={data.image3.childImageSharp.fluid}
                 alt="Frontend Development"
               />
             </div>
             <div className="ef-title">
               <h3>
                 Backend <br /> Developnent
               </h3>
             </div>
             <div className="ef-desc">
               <p>
                 I have got extensive knowledge of backend development, whether
                 it be a Built in CMS like Wordpress or building bespoke CMS
                 using PHP and its framework.
               </p>
             </div>
           </div>
         </Col>
       </Row>
     </Container>
   </FeatureWrapper>
 )
}

export default Features;


export const query = graphql`
  fragment featuresImage on File {
    childImageSharp {
      fluid {
        ...GatsbyImageSharpFluid
        ...GatsbyImageSharpFluidLimitPresentationSize
      }
    }
  }
`