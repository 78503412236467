import React from "react"
import Row from "react-bootstrap/Row"
import Container from "react-bootstrap/Container"
import Col from "react-bootstrap/Col"
import Carousel from "react-bootstrap/Carousel"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const TestimonialWrapper = styled.section`
  background-color: #0c0c0c;
  .carousel-items {
    margin-top: 50px;
    .carousel-item{
     padding: 40px;
     background-color: #222;
    }
    .ti-img{
     margin-bottom: 30px;
     .gatsby-image-wrapper{
      margin: 0 auto;
     }
    }
    .ci-info{
     min-height: 80px;
     p{
      &:last-child{
       margin-bottom: 0;
      }
     }
    }
    .ci-by{
     margin-top: 40px;
     span{
      display: block;
      &:first-child{
       color: #fff;
       font-size: 18px;
       font-weight: 600;
       margin-bottom: 5px;
      }
     }
    }
  }
  .carousel-indicators {
    bottom: -80px;
    display: flex;
    align-items: center;
    li {
      padding: 0;
      height: 8px;
      width: 8px;
      border-radius: 100%;
      background-color: #9c9c9c;
      &.active {
        height: 12px;
        width: 12px;
      }
    }
  }
`

const Testimonials = () => {
  const data = useStaticQuery(graphql`
    query {
      image1: file(relativePath: { eq: "digital-lagos.png" }) {
        ...testimonialsImage
      }

      image2: file(relativePath: { eq: "ppc-logo.png" }) {
        ...testimonialsImage
      }

      image3: file(relativePath: { eq: "dc-logo.png" }) {
        ...testimonialsImage
      }
    }
  `)

  return (
    <TestimonialWrapper className="section">
      <Container>
        <div className="section-info text-center">
          <div className="si-title">
            <h2>Testimonials</h2>
            <span>Nice Words</span>
          </div>
          <Row className="justify-content-center">
            <Col xs={8}>
              <div className="si-desc">
                <p>
                  It feels so good to hear nicest of the words from clients. It{" "}
                  <span>motivates</span> me to put some more effort into the
                  work, period.
                </p>
              </div>
            </Col>
          </Row>
        </div>

        <Row className="justify-content-center text-center">
          <Col xs={6}>
            <div className="carousel-items">
              <Carousel controls={false}>
                <Carousel.Item>
                  <div className="ti-img">
                    <Img
                      fluid={data.image1.childImageSharp.fluid}
                      alt="100% End Result"
                    />
                  </div>
                  <div className="ci-info">
                    <p>
                      "A pleasure working with Ankit. He know his work, follows
                      instruction and meets deadline. I am looking forward to
                      working with him in my next project"
                    </p>
                  </div>
                  <div className="ci-by">
                    <span> - Godwin Ogene</span>
                    <span>Digital Lagos, UK</span>
                  </div>
                </Carousel.Item>
                <Carousel.Item>
                  <div className="ti-img">
                    <Img
                      fluid={data.image2.childImageSharp.fluid}
                      alt="100% End Result"
                    />
                  </div>
                  <div className="ci-info">
                    <p>
                      "Master of all trades! I've asked him to do many different
                      tasks and he was great! Thanks"
                    </p>
                  </div>
                  <div className="ci-by">
                    <span> - Pesach Gelfand</span>
                    <span>Pixel Perfect Creation, USA</span>
                  </div>
                </Carousel.Item>
                <Carousel.Item>
                  <div className="ti-img">
                    <Img
                      fluid={data.image3.childImageSharp.fluid}
                      alt="100% End Result"
                    />
                  </div>
                  <div className="ci-info">
                    <p>
                      "Ankit is an excellent freelancer. Dependable and
                      qualified. Great communicator."
                    </p>
                  </div>
                  <div className="ci-by">
                    <span> - Sam Natello</span>
                    <span>Dot Com Global Media, USA</span>
                  </div>
                </Carousel.Item>
              </Carousel>
            </div>
          </Col>
        </Row>
      </Container>
    </TestimonialWrapper>
  )
}

export default Testimonials

export const query = graphql`
  fragment testimonialsImage on File {
    childImageSharp {
      fluid {
        ...GatsbyImageSharpFluid
        ...GatsbyImageSharpFluidLimitPresentationSize
      }
    }
  }
`
